<template>
  <div class="table-responsive table-container mt-2" :class="smallSize ? '' : 'pipeline-jobs-container'">
    <table class="table">
      <thead class="bg-gray-500 text-uppercase">
      <tr>
        <th>Market</th>
        <th @click="onSortClick('date')" class="pointer" :class="sortField === 'date' ? 'text-primary' : ''">
          Date <span v-if="sortField === 'date'">({{ ascSort ? '▲' : '▼' }})</span>
        </th>
        <th>Details</th>
        <th v-if="!hideStats">Count</th>
        <th v-if="!hideStats">Total Devices</th>
        <th v-if="!hideStats">Unique Devices</th>
        <th v-if="!hideStats">Total Matches</th>
        <th v-if="!hideStats">Unique Matches</th>
        <th v-if="!hideStats">Duration</th>
        <th @click="onSortClick('processingStartTime')" class="pointer" :class="sortField === 'runDate' ? 'text-primary' : ''">
          Run Date <span v-if="sortField === 'processingStartTime'">({{ ascSort ? '▲' : '▼' }})</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="job in sortedJobs">
        <td>{{ getMarketName(job.marketHash) }}</td>
        <td>{{ job.date }}</td>
        <td>
          <div>{{ job.jobType }}</div>
          <div>{{ job.matchGroup }}</div>
          <span v-if="job.status === 'GETTING_NEAR_DEVICES_AND_CALCULATING_MATCHES'"
                class="border rounded py-1 px-1 font-xsm" :class="getJobStatusClasses('PROCESSING')">PROCESSING</span>
          <span v-else class="border rounded py-1 px-1 font-xsm" :class="getJobStatusClasses(job.status)">{{ job.status }}</span>
        </td>
        <td v-if="!hideStats">
          <span v-if="job.jobType === 'STATIONARY'">{{ job.totalFrames }} (frames)</span>
          <span v-else-if="job.jobType === 'TRANSIT'">{{ job.totalAssets }} (assets)</span>
        </td>
        <td v-if="!hideStats">{{ job.totalPins.toLocaleString() }}</td>
        <td v-if="!hideStats">{{ job.uniquePins.toLocaleString() }}</td>
        <td v-if="!hideStats">{{ job.totalMatches.toLocaleString() }}</td>
        <td v-if="!hideStats">{{ job.totalUniqueMatches.toLocaleString() }}</td>
        <td v-if="!hideStats">{{ formatDuration(job.duration) }}</td>
        <td>
          <div>{{ formatTimestamp(job.processingStartTime) }}</div>
          <div v-if="job.runBy">Run by: {{ job.runBy }}</div>
          <div>
            <button @click="onReRunClick(job)" class="btn btn-sm btn-danger">Re-run</button>
            <button v-if="showDeleteAndRerun(job)" @click="onDeleteClick(job)" class="ms-2 btn btn-sm btn-danger">Delete</button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <confirm-modal
        key="confirmRerunModal"
        v-if="showRerunModal"
        @noEvent="showRerunModal = false"
        :yesEvent="onRerunConfirmed"
        text="Are you sure you want to re-run this job?">
    </confirm-modal>

    <confirm-modal
        key="confirmDeleteModal"
        v-if="showDeleteModal"
        @noEvent="showDeleteModal = false"
        :yesEvent="onDeleteConfirmed"
        text="Are you sure you want to delete this job?">
    </confirm-modal>

  </div>
</template>

<script>
import dayjs from "dayjs";
import streetmetrics from "@/model";
import AppUtil from "@/utils/AppUtil";
import ConfirmModal from "@/components/ConfirmModal.vue";
import PipelineService from "@/services/PipelineService";

export default {
  name: 'PipelineJobsTable',
  components: {ConfirmModal},
  props: ['jobs', 'marketsHashTable', 'smallSize', 'hideStats'],
  data () {
    return {
      model: streetmetrics.model,
      currentJob: null,
      showRerunModal: false,
      showDeleteModal: false,
      sortField: '',
      ascSort: true,
    }
  },
  computed: {
    sortedJobs() {
      if (!this.sortField) {
        return [...this.jobs];
      }
      
      return [...this.jobs].sort((a, b) => {
        const aVal = a[this.sortField];
        const bVal = b[this.sortField];
        
        if (this.ascSort) {
          return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
        }
        return bVal > aVal ? 1 : bVal < aVal ? -1 : 0;
      });
    }
  },
  methods: {
    showDeleteAndRerun(job) {
      const jobStart = dayjs(job.processingStartTime).format('YYYY-MM-DD')
      const today = dayjs().format('YYYY-MM-DD')
      return job.status === 'FAILED' || (jobStart < today && job.status !== 'PENDING' && job.status !== 'COMPLETED')
    },
    getMarketName(marketHash) {
      return this.marketsHashTable[marketHash].marketName
    },

    formatDuration(duration) {
      return streetmetrics.utils.string.formatDuration(duration)
    },

    formatTimestamp(timestamp) {
      return dayjs(timestamp).format('MM/DD/YYYY h:mm:ss A')
    },

    getJobStatusClasses(jobStatus) {
      if(jobStatus === 'COMPLETED') {
        return 'border-success text-success bg-green-100';
      }
      else if(jobStatus === 'FAILED') {
        return 'border-danger text-danger bg-red-100';
      }

      return 'border-warning text-warning bg-yellow-100';
    },

    onReRunClick(job) {
      this.currentJob = job
      this.showRerunModal = true
    },

    onDeleteClick(job) {
      this.currentJob = job
      this.showDeleteModal = true
    },

    onRerunConfirmed() {
      const rerunPath = '/api/re-run-job'
      this.executeJobRequest(rerunPath)
    },

    onDeleteConfirmed() {
      const deletePath = '/api/delete-job'
      this.executeJobRequest(deletePath)
    },

    executeJobRequest(path) {
      const request = {
        jobId: this.currentJob.jobId
      }

      const url = new AppUtil().getPipelineUrl() + path
      const service = new PipelineService()
      service.post(url, request).then( (response) => {
        console.log('Re-run successful')
        streetmetrics.eventDispatcher.emit('refreshJobsEvent')
      }).catch((error) => {
        console.log('Re-run failed')
        console.log(error)
      });
    },

    onSortClick(field) {
      this.sortField = field
      this.ascSort = !this.ascSort
      this.$forceUpdate(); 
    }
  }
}
</script>
