import RestService from "./RestService";

export default class AccountService extends RestService {
  constructor() {
    super("accounts");
  }

  getBillingReport(customerId) {
    let url = this.baseUrl + "/v3/api/accounts/invoice-metrics/" + customerId;
    return this.executeGet(url);
  }
}
