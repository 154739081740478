<template>
    <div class="pt-4 px-4">

        <div class="mb-3 d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <h2>New Campaign Job</h2>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label">Run Type</label>
            <select class="form-select" v-model="job.runType">
                <option value="STATIONARY">Stationary</option>
                <option value="TRANSIT">Transit</option>
                <option value="">All</option>
            </select>
        </div>

        <div class="mb-3">
            <label class="form-label">Job Type</label>
            <select class="form-select" v-model="job.jobType">
                <option value="NEAR_TO_GOLD">Full Matching</option>
                <option value="BRONZE_TO_GOLD">Devices to Impressions</option>
            </select>
        </div>

        <div class="mb-3">
            <label class="form-label">Customer</label>
            <select class="form-select" v-model="job.customerId">
                <option v-for="customer in model.customers" :value="customer.customerId">{{ customer.customerName }}
                </option>
            </select>
        </div>

        <div class="mb-3">
            <label class="form-label">Campaign(s)</label>
            <input v-model="job.campaignIds" type="text" class="form-control"
                placeholder="[221023,221010,221067,221137,221104]">
        </div>

        <div class="mb-3">
            <button type="button" class="btn btn-secondary" style="min-width: 150px;" @click="onSaveClick">Save
            </button>

            <router-link :to="{ name: 'pipeline' }" class="btn btn-light ms-2">
                Cancel
            </router-link>
        </div>

    </div>
</template>

<script>
import PipelineService from "@/services/PipelineService";
import AppUtil from "@/utils/AppUtil";
import Stationary from "@/views/pipeline/Stationary.vue";
import dayjs from "dayjs";
import CampaignService from "@/services/CampaignService";
import streetmetrics from "@/model";

export default {
    name: 'CampaignJobForm',
    components: { Stationary },
    props: ['prop1', 'prop2'],
    data() {
        return {
            model: streetmetrics.model,
            service: new PipelineService(),
            campaigns: [],
            job: {
                marketId: null,
                startDate: '',
                endDate: '',
                matchGroup: 'exposed',
                runType: 'STATIONARY',
                customerId: null,
                campaignId: null,
                jobType: 'NEAR_TO_GOLD',
            }
        }
    },
    methods: {
        onSaveClick() {
            const request = {
                matchGroup: this.job.matchGroup,
                runType: this.job.runType,
                runBy: this.model.user.displayName,
                customerId: this.job.customerId,
                campaignIds: this.job.campaignIds,
                jobType: this.job.jobType
            }
            const url = new AppUtil().getPipelineUrl() + '/api/run-campaigns'
            
            this.service.post(url, request).then((response) => {
                this.model.toast.background = 'bg-green';
                this.model.toast.message = response.message;
                this.$router.push({ name: 'pipeline' });
            }).catch((error) => {
                this.model.toast.background = 'bg-red';
                this.model.toast.message =
                    'There was an error trying to run this job. Please try again later.';
            });
        }
    }
}
</script>
