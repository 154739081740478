<template>
  <div class="ms-3 mt-3" v-if="customer.customerId">
    <div class="my-3 me-3 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h3 class="mb-3 fw-bold spacing-close">{{ customer.customerName }}</h3>
      </div>

      <div>
        <button class="btn btn-secondary" @click="onDownloadBillingReportClick">
          Download Billing Report
        </button>
        <button class="ms-2 btn btn-secondary" @click="onEditCustomerClick">
          Edit Customer
        </button>
        <button class="ms-2 btn btn-secondary" @click="onUpdateCampaignsClick">
          Update Campaign Metadata
        </button>
      </div>
    </div>

    <customer-tabs
      :view-model="viewModel"
      @tabChangedEvent="onChildViewSelected"
    ></customer-tabs>

    <div>
      <div
        v-if="viewModel.childView === 'markets'"
        class="mt-2 d-flex flex-wrap"
      >
        <div v-for="marketId in customer.markets" class="card shadow-sm m-2">
          <div class="card-body">
            {{ getMarket(marketId).marketName }}
          </div>
        </div>
      </div>

      <campaigns
        v-if="viewModel.childView === 'campaigns'"
        :customer-id="customer.customerId"
        :view-model="viewModel"
      ></campaigns>
      <vehicles
        v-if="viewModel.childView === 'vehicles'"
        :customer="customer"
        :view-model="viewModel"
      ></vehicles>
      <user-list
        v-else-if="viewModel.childView === 'users'"
        :customer-id="customer.customerId"
      ></user-list>
      <campaign-details
        v-else-if="viewModel.childView === 'campaign-details'"
        :customer="customer"
        :view-model="viewModel"
      ></campaign-details>
      <pixel-details
        v-else-if="viewModel.childView === 'campaign-pixel-details'"
        :customer="customer"
        :view-model="viewModel"
      ></pixel-details>
    </div>

    <customer-form-modal
      v-if="showModal"
      @noEvent="showModal = false"
      :customer="customerClone"
      :yes-event="onCustomerSave"
    ></customer-form-modal>
  </div>
</template>

<script>
import ArrayUtil from "../utils/ArrayUtil";
import CustomerTabs from "../components/CustomerTabs";
import Campaigns from "./Campaigns";
import UserList from "./UserList";
import CampaignDetails from "./CampaignDetails";
import PixelDetails from "./PixelDetails";
import CustomerService from "../services/CustomerService";
import Vehicles from "./Vehicles";
import CustomerFormModal from "../components/CustomerFormModal";
import CampaignService from "@/services/CampaignService";
import AccountService from "@/services/AccountService";
import AppUtil from "@/utils/AppUtil";
export default {
  name: "CustomerDetails",
  components: {
    CustomerFormModal,
    Vehicles,
    PixelDetails,
    CampaignDetails,
    UserList,
    Campaigns,
    CustomerTabs,
  },
  props: ["customerUrlFriendlyId"],
  data() {
    return {
      model: streetmetrics.model,
      customer: {
        customerId: null,
        customerName: null,
        customerChannel: "",
        subscriptionType: "",
        markets: [],
      },
      viewModel: {
        childView: "markets", // markets, campaigns, vehicles, users, campaign-details, OR campaign-pixel-details
        currentCampaignId: null,
        currentPixel: null,
      },
      utils: new AppUtil(),
      showModal: false,
      customerClone: {
        customerId: null,
        customerName: "",
        customerChannel: "",
        subscriptionType: "",
        markets: [],
      },
      onCustomerSave: () => {},
    };
  },
  mounted() {
    const arrayUtil = new ArrayUtil();
    this.customer = arrayUtil.find(
      this.model.customers,
      this.customerUrlFriendlyId,
      "slug"
    );
  },
  methods: {
    onChildViewSelected(childView) {
      this.viewModel.childView = childView;
    },
    onUpdateCampaignsClick() {
      this.model.loading = true;
      let customerId = this.customer.customerId;
      let service = new CampaignService();
      service
        .updateCustomerCampaigns(customerId)
        .then((response) => {
          console.log("Campaigns updated");
          console.log(response);
          this.model.loading = false;
        })
        .catch((error) => {
          console.log("Error updating campaigns");
          console.log(error);
          this.model.loading = false;
        });
    },
    onEditCustomerClick() {
      this.customerClone = JSON.parse(JSON.stringify(this.customer));
      this.showModal = true;

      this.onCustomerSave = (customer) => {
        console.log("onCustomerSave");
        console.log(customer);
        this.customer = customer;

        let arrayUtil = new ArrayUtil();
        arrayUtil.updateItem(this.model.customers, customer, "customerId");
      };
    },
    onDownloadBillingReportClick() {
      let service = new AccountService();
      service
        .getBillingReport(this.customer.customerId)
        .then((response) => {
          const data = response.data;
          const csvHeader =
            "Customer ID,Contract ID,Campaign ID,Campaign Ref,Advertiser,Active From,Active To,Asset Count,Frame Count\n";
            const escapeCommas = (value) => {
              if (typeof value === 'string' && value.includes(',')) {
                return `"${value}"`;
              }
              return value;
            };
            const csvRows = data
            .map((item) => {
              return [
                item.customerId || "",
                item.contractId || "",
                item.campaignId || "",
                item.campaignRef || "",
                item.advertiser || "",
                item.activeFrom || "",
                item.activeTo || "",
                item.assetCount || 0, 
                item.frameCount || 0,
              ].map(escapeCommas).join(",");
            })
            .join("\n");
          this.utils.downloadCSV(csvRows, "billing_metrics.csv", csvHeader);
        })
        .catch((error) => {
          console.log("Error downloading billing report");
          console.log(error);
        });
    },
    getMarket(marketId) {
      return this.model.markets.find((market) => market.marketId === marketId);
    },
  },
};
</script>

<style></style>
