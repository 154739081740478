<template>
  <div>
    <div class="d-flex flex-wrap mt-4">
      <div class="card shadow-sm mx-2 my-1" style="min-width: 250px;">
        <div class="card-body">
          <div class="text-center">
            <h1 class="font-2xl font-xbold spacing-close accent-color">{{ pendingJobs.length }}</h1>
            <p class="card-text">
              Jobs in Queue
            </p>
          </div>
        </div>
      </div>

      <div class="card shadow-sm mx-2 my-1" style="min-width: 250px;">
        <div class="card-body">
          <div class="text-center">
            <h1 class="font-2xl font-xbold spacing-close accent-color">{{ completedJobsToday.length }}</h1>
            <p class="card-text">
              Jobs Completed Today
            </p>
          </div>
        </div>
      </div>

      <div class="card shadow-sm mx-2 my-1" style="min-width: 250px;">
        <div class="card-body">
          <div class="text-center">
            <h1 class="font-2xl font-xbold spacing-close accent-color">{{ failedJobs.length }}</h1>
            <p class="card-text">
              Failed Jobs
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="my-3" v-if="completedJobsToday.length > 0">
      <div>Total Processing Time Today: {{ processingDurationToday }}</div>
    </div>

    <div class="my-3" v-if="runningJobs.length > 0">
      <div class="font-xbold">Currently running jobs</div>
      <pipeline-jobs-table :jobs="runningJobs" :markets-hash-table="marketsHashTable"
                           :small-size="true" :hide-stats="true"></pipeline-jobs-table>
    </div>
    <div v-else class="my-3" >
      <div class="font-xbold">No jobs are currently running.</div>
    </div>

    <div class="mb-3" v-if="failedJobs.length > 0">
      <div class="font-xbold">Failed jobs</div>
      <pipeline-jobs-table :jobs="failedJobs" :markets-hash-table="marketsHashTable"
                           :small-size="true" :hide-stats="true"></pipeline-jobs-table>
    </div>

    <div class="mb-3" v-if="pendingJobs.length > 0">
      <div class="font-xbold">Pending jobs</div>
      <pipeline-jobs-table :jobs="pendingJobs" :markets-hash-table="marketsHashTable"
                           :small-size="true" :hide-stats="true"></pipeline-jobs-table>
    </div>

  </div>
</template>

<script>
import dayjs from "dayjs";
import streetmetrics from "@/model";
import PipelineJobsTable from "@/views/pipeline/PipelineJobsTable.vue";

export default {
  name: 'JobDashboard',
  components: {PipelineJobsTable},
  props: ['jobs', 'marketsHashTable'],
  data() {
    return {
      model: streetmetrics.model
    }
  },
  computed: {
    runningJobs() {
      // const today = dayjs().format('MM/DD/YYYY')

      return this.jobs.filter((job) => {
        // const jobDate = dayjs(job.processingStartTime).format('MM/DD/YYYY')
        //return today === jobDate && job.status !== 'PENDING' && job.status !== 'COMPLETED' && job.status !== 'FAILED'
        return job.status !== 'PENDING' && job.status !== 'COMPLETED' && job.status !== 'FAILED' && job.status !== 'POSTPONED'
      })
    },
    failedJobs() {
      return this.jobs.filter((job) => {
        return job.status === 'FAILED'
      })
    },
    pendingJobs() {
      return this.jobs.filter((job) => {
        return job.status === 'PENDING'
      })
    },
    completedJobsToday() {
      const today = dayjs().format('MM/DD/YYYY')

      return this.jobs.filter((job) => {
        const jobDate = dayjs(job.processingStartTime).format('MM/DD/YYYY')
        return job.status === 'COMPLETED' && today === jobDate
      })
    },
    failedJobsToday() {
      const today = dayjs().format('MM/DD/YYYY')

      return this.jobs.filter((job) => {
        const jobDate = dayjs(job.processingStartTime).format('MM/DD/YYYY')
        return job.status === 'FAILED' && today === jobDate
      })
    },
    processingDurationToday() {
      const totalDuration = this.completedJobsToday.reduce((accumulator, currentJob) => {
        return accumulator + currentJob.duration
      }, 0);

      return streetmetrics.utils.string.formatDuration(totalDuration);
    },
  },
  mounted() {
  },
  methods: {
    importantMethodHere() {
    }
  }
}
</script>
